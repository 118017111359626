import { MetricType } from "@/healthcheck/model/MetricType";
import { MetricId } from "@/healthcheck/model/MetricId";
import { MetricWeightResponseModel } from "@/healthcheck/model/MetricWeightResponseModel";

export class MetricWeightRequestModel {
  constructor(
    public metricType: MetricType | null = null,
    public metricId: MetricId | null = null,
    public application: string = "",
    public weight: number | null = null,
    public id?: number
  ) {}

  static of(
    item: MetricWeightRequestModel | MetricWeightResponseModel | null
  ): MetricWeightRequestModel {
    return new MetricWeightRequestModel(
      item?.metricType ?? null,
      item?.metricId ?? null,
      item?.application ?? "",
      item?.weight ?? null,
      item?.id
    );
  }
}
