import { MetricType } from "@/healthcheck/model/MetricType";
import { MetricId } from "@/healthcheck/model/MetricId";

export class MetricWeightUtils {
  static readonly METRIC_IDS_BY_TYPE: Record<MetricType, MetricId[]> = {
    [MetricType.DATAFLOW]: [
      MetricId.LOST_DATA,
      MetricId.LONG_PROCESSING,
      MetricId.NOT_FINISHED,
      MetricId.FAILED,
    ],
    [MetricType.PARSING]: [
      MetricId.INSTALLS,
      MetricId.DAUS,
      MetricId.CLICKS,
      MetricId.REINSTALLS,
      MetricId.REATTRIBUTIONS,
      MetricId.REATTRIBUTION_REINSTALLS,
      MetricId.UNINSTALLS,
      MetricId.REJECTED_INSTALLS,
      MetricId.REJECTED_REATTRIBUTIONS,
      MetricId.SESSIONS,
      MetricId.PHONE,
      MetricId.TABLET,
    ],
    [MetricType.SEGMENT]: [
      MetricId.OUTDATED_STATISTIC,
      MetricId.HANGED_STATISTIC_RECALCULATION,
      MetricId.OUTDATED_SEGMENT,
      MetricId.OUTDATED_SEGMENT_RECALCULATION,
    ],
    [MetricType.THIRD_PARTY]: [MetricId.FAILED_ACCOUNT_GROUP_STATISTICS],
  };

  public static provideMetricTypes(): MetricType[] {
    return [
      MetricType.DATAFLOW,
      MetricType.PARSING,
      MetricType.SEGMENT,
      MetricType.THIRD_PARTY,
    ];
  }

  public static provideMetricIdsByType(
    metricType: MetricType | null
  ): MetricId[] {
    return metricType ? this.METRIC_IDS_BY_TYPE[metricType] : [];
  }
}
