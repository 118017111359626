export enum MetricId {
  /*parsing metrics:*/
  INSTALLS = "INSTALLS",
  DAUS = "DAUS",
  CLICKS = "CLICKS",
  REINSTALLS = "REINSTALLS",
  REATTRIBUTIONS = "REATTRIBUTIONS",
  REATTRIBUTION_REINSTALLS = "REATTRIBUTION_REINSTALLS",
  UNINSTALLS = "UNINSTALLS",
  REJECTED_INSTALLS = "REJECTED_INSTALLS",
  REJECTED_REATTRIBUTIONS = "REJECTED_REATTRIBUTIONS",
  SESSIONS = "SESSIONS",
  PHONE = "PHONE",
  TABLET = "TABLET",

  /*segment metrics:*/
  OUTDATED_STATISTIC = "OUTDATED_STATISTIC",
  HANGED_STATISTIC_RECALCULATION = "HANGED_STATISTIC_RECALCULATION",
  OUTDATED_SEGMENT = "OUTDATED_SEGMENT",
  OUTDATED_SEGMENT_RECALCULATION = "OUTDATED_SEGMENT_RECALCULATION",

  /*third party metrics:*/
  FAILED_ACCOUNT_GROUP_STATISTICS = "FAILED_ACCOUNT_GROUP_STATISTICS",

  /*dataflow metrics:*/
  LOST_DATA = "LOST_DATA",
  LONG_PROCESSING = "LONG_PROCESSING",
  NOT_FINISHED = "NOT_FINISHED",
  FAILED = "FAILED",
}
