















































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import { MetricWeightRequestModel } from "@/healthcheck/model/MetricWeightRequestModel";
import { MetricWeightUtils } from "@/healthcheck/utils/MetricWeightUtils";
import { Application } from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";
import { MetricId } from "@/healthcheck/model/MetricId";
import { MetricType } from "@/healthcheck/model/MetricType";

@Component
export default class MetricWeightFormDialog extends Vue {
  @Prop() value!: boolean;
  @Prop() metricWeight!: MetricWeightRequestModel | null;
  @Ref("form") readonly form!: VueForm;

  readonly DEFAULT_APPLICATION = "default";
  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly REQUIRED_NUMBER = [
    ValidUtil.requiredNumber(this.$lang("validation.number")),
  ];
  valid = false;
  metricWeightLocal = new MetricWeightRequestModel();

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
  }

  get title(): string {
    return this.metricWeight
      ? this.$lang("metricWeights.updateDialogHeader")
      : this.$lang("metricWeights.createDialogHeader");
  }

  get metricTypes(): MetricType[] {
    return MetricWeightUtils.provideMetricTypes();
  }

  get metricIds(): MetricId[] {
    return MetricWeightUtils.provideMetricIdsByType(
      this.metricWeightLocal.metricType
    );
  }

  get applications(): Array<string> {
    return [
      this.DEFAULT_APPLICATION,
      ...this.$store.state.application.apps.map((app: Application) => app.id),
    ];
  }

  get isMetricWeightsSaving(): boolean {
    return this.$store.state.healthCheckStore.isMetricWeightsSaving;
  }

  @Watch("value", { immediate: true })
  async watchValue() {
    if (!this.value) {
      return;
    }
    this.metricWeightLocal = MetricWeightRequestModel.of(this.metricWeight);
    await this.$nextTick();
    this.form.resetValidation();
  }

  save() {
    if (!this.form.validate()) {
      return;
    }
    this.$emit(this.metricWeight ? "update" : "create", this.metricWeightLocal);
  }
}
