















































































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import MetricWeightFormDialog from "@/healthcheck/components/MetricWeightFormDialog.vue";
import { MetricWeightRequestModel } from "@/healthcheck/model/MetricWeightRequestModel";
import { MetricWeightResponseModel } from "@/healthcheck/model/MetricWeightResponseModel";
import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";

@Component({
  components: { ConfirmationDialog, MetricWeightFormDialog },
})
export default class HealthCheckMetricWeightsView extends Vue {
  readonly HEADERS = [
    {
      text: this.$lang("metricWeights.headers.metricType"),
      align: "start",
      value: "metricType",
    },
    {
      text: this.$lang("metricWeights.headers.metricId"),
      value: "metricId",
    },
    {
      text: this.$lang("metricWeights.headers.application"),
      value: "application",
    },
    {
      text: this.$lang("metricWeights.headers.weight"),
      value: "weight",
    },
    {
      text: this.$lang("metricWeights.headers.actions"),
      value: "actions",
      sortable: false,
      align: "end",
    },
  ];

  search = "";
  deleteDialogVisible = false;
  isCreateUpdateDialogVisible = false;
  metricWeightIdBeingDeleted: number | null = null;
  metricWeightBeingCreatedOrUpdated: MetricWeightRequestModel | null = null;

  get metricWeights(): Array<MetricWeightResponseModel> {
    return this.$store.state.healthCheckStore.metricWeights;
  }

  get isMetricWeightsLoading(): boolean {
    return this.$store.state.healthCheckStore.isMetricWeightsLoading;
  }

  get isMetricWeightsDeleting(): boolean {
    return this.$store.state.healthCheckStore.isMetricWeightsDeleting;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("metricWeights.title")
    );
    this.$store.dispatch("getMetricWeights");
  }

  handleClickByTableRow(item: MetricWeightRequestModel) {
    this.metricWeightBeingCreatedOrUpdated = item;
    this.isCreateUpdateDialogVisible = true;
  }

  showDeleteDialog(id: number) {
    this.deleteDialogVisible = true;
    this.metricWeightIdBeingDeleted = id;
  }

  showCreateDialog() {
    this.metricWeightBeingCreatedOrUpdated = null;
    this.isCreateUpdateDialogVisible = true;
  }

  async createMetricWeight(item: MetricWeightRequestModel) {
    await this.$store.dispatch("createMetricWeight", item);
    this.isCreateUpdateDialogVisible = false;
  }

  async updateMetricWeight(item: MetricWeightRequestModel) {
    await this.$store.dispatch("updateMetricWeight", item);
    this.isCreateUpdateDialogVisible = false;
  }

  async deleteMetricWeight() {
    await this.$store.dispatch(
      "deleteMetricWeight",
      this.metricWeightIdBeingDeleted
    );
    this.deleteDialogVisible = false;
  }
}
